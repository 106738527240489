import { initializeApp } from "firebase/app";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import "./App.css";
import db from "./firebase";
import { AuthContextProvider, useAuthState, useUserID } from "./firebase";
import { getAuth, signOut } from "firebase/auth";
import logo from './images/logo.png';
import { Line } from 'react-chartjs-2';
import DateTimePicker from 'react-datetime-picker';
import Alert from 'react-bootstrap/Alert';

var arrayHeader = ["timestamp","740nm10mm","940nm10mm","850nm10mm","ambient27mm","740nm27mm","940nm27mm","850nm27mm","hbo","hbr","hbt"];
var arrayMetricHeader = ["timestamp","flow_activity","variability","low_freq_oscillations","prv","heartrate","peakToPeakMs","steps","accelMag"];
var deviceVersion = "2022";
let arrayData = [];
let arrayMetricData = [];
let arrayData10mm = [];
let arrayData27mm = [];

export const Home = () => {
  
  var timeData = [];
  var labelsData = [];
  var labelsData10mm = [];
  var labelsData27mm = [];
  var dataAmbient10mm = [];
  var dataAmbient27mm = [];
  var data740nm10mm = [];
  var data940nm10mm = [];
  var data850nm10mm = [];
  var data740nm27mm = [];
  var data940nm27mm = [];
  var data850nm27mm = [];
  var dataHbO = [];
  var dataHbR = [];
  var dataHbT = [];   

  var timeMetricData = [];
  var labelsMetricData = [];
  var mentalActivityData = [];
  var stressData = [];
  var energyData = [];
  var prvData = [];
  var hrData = [];
  var peakToPeakData = [];
  var stepData = [];
  var accelMagData = [];
  var rawDataComputedMetricDoc = true;

  var { updatedID } = useUserID();
  var userID = "6GUfarBavscMlw8M6EKS4wEVgx03";

  const [startTime, onChangeStart] = useState(new Date(Date.now() - 1000 * 60 * 30)); //default to last 30 minutes of data
  const [endTime, onChangeEnd] = useState(new Date());
  const [show, setShow] = useState(false);

  const [chartData10mm, setChartData10mm] =  React.useState({
   labels: labelsData10mm,
   datasets: [
    {
      data:[],
      label: '740nm10mm',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: '940nm10mm',
      backgroundColor: 'rgba(99, 200, 50, 0.5)',
      borderColor: 'rgb(99, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: '850nm10mm',
      backgroundColor: 'rgba(50, 99, 200, 0.5)',
      borderColor: 'rgb(50, 99, 200)',
      fill: false,
      strokeWidth: 2
    }
   ] 
  })

  const [chartData27mm, setChartData27mm] = React.useState({
   labels: labelsData27mm,
   datasets: [
    {
      data:[],
      label: 'ambient27mm',
      backgroundColor: 'rgba(50, 99, 50, 0.5)',
      borderColor: 'rgb(50, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: '740nm27mm',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: '940nm27mm',
      backgroundColor: 'rgba(99, 200, 50, 0.5)',
      borderColor: 'rgb(99, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: '850nm27mm',
      backgroundColor: 'rgba(50, 99, 200, 0.5)',
      borderColor: 'rgb(50, 99, 200)',
      fill: false,
      strokeWidth: 2
    }
   ]
  })

  const [chartDataHB, setChartDataHB] = React.useState({
   labels: labelsData27mm,
   datasets: [
    {
      data:[],
      label: 'hbo',
      backgroundColor: 'rgba(50, 50, 200, 0.5)',
      borderColor: 'rgb(50, 50, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'hbr',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'hbt',
      backgroundColor: 'rgba(50, 200, 50, 0.5)',
      borderColor: 'rgb(50, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: false
    }
   ]
  })

  const [chartMetricData, setChartMetricData] = React.useState({
   labels: labelsMetricData,
   datasets: [
    {
      data:[],
      label: 'Flow activity (%)',
      backgroundColor: 'rgba(200, 50, 200, 0.5)',
      borderColor: 'rgb(200, 50, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: false
    },
    {
      data:[],
      label: 'Variability',
      backgroundColor: 'rgba(200, 99, 50, 0.5)',
      borderColor: 'rgb(200, 99, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Low freq. oscillations',
      backgroundColor: 'rgba(99, 200, 50, 0.5)',
      borderColor: 'rgb(99, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'PRV SDANN (ms)',
      backgroundColor: 'rgba(50, 99, 200, 0.5)',
      borderColor: 'rgb(50, 99, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Heart rate (bpm)',
      backgroundColor: 'rgba(200, 50, 50, 0.5)',
      borderColor: 'rgb(200, 50, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: false
    },
    {
      data:[],
      label: 'Peak to peak (millis)',
      backgroundColor: 'rgba(200, 200, 50, 0.5)',
      borderColor: 'rgb(200, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Steps',
      backgroundColor: 'rgba(200, 200, 50, 0.5)',
      borderColor: 'rgb(50, 200, 50)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    },
    {
      data:[],
      label: 'Accel. magnitude',
      backgroundColor: 'rgba(50, 50, 200, 0.5)',
      borderColor: 'rgb(50, 50, 200)',
      fill: false,
      strokeWidth: 2,
      hidden: true
    }
   ]
  });

  const getTimeQueryData = () => {

    clear_data();

    var millisStart = startTime.getTime()/1000.0
    var millisEnd = endTime.getTime()/1000.0
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }
    
    userID = window.userID;
    // console.log("query uid: " + userID);

    if (!rawDataComputedMetricDoc){
      var data = db.collection("userfNIRSData/"+userID+"/raw_data").where('timestamp_server', '>=', millisStart).where('timestamp_server', '<=', millisEnd).orderBy('timestamp_server', 'desc');
      data.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              timeData.push(...doc.data()["timestamp_edge"].reverse());
              data740nm10mm.push(...doc.data()["led740nm_10mm"].reverse());
              data940nm10mm.push(...doc.data()["led940nm_10mm"].reverse());
              data850nm10mm.push(...doc.data()["led850nm_10mm"].reverse());
              data740nm27mm.push(...doc.data()["led740nm_27mm"].reverse());
              data940nm27mm.push(...doc.data()["led940nm_27mm"].reverse());
              data850nm27mm.push(...doc.data()["led850nm_27mm"].reverse());
              dataHbO.push(...doc.data()["hbo"].reverse());
              dataHbR.push(...doc.data()["hbr"].reverse());
              dataHbT.push(...doc.data()["hbt"].reverse());
              if (doc.data()["ambient_27mm"] !== undefined){
                dataAmbient27mm.push(...doc.data()["ambient_27mm"].reverse());
              } 
          });

          let tLen = timeData.length;
          for (let i = 0; i < tLen; i++) {
            if (dataAmbient27mm.length > 0){
              arrayData.push([timeData[i],data740nm10mm[i],data940nm10mm[i],data850nm10mm[i],dataAmbient27mm[i],data740nm27mm[i],data940nm27mm[i],data850nm27mm[i],dataHbO[i],dataHbR[i],dataHbT[i]]);
            } else {
              arrayData.push([timeData[i],data740nm10mm[i],data940nm10mm[i],data850nm10mm[i],0.0,data740nm27mm[i],data940nm27mm[i],data850nm27mm[i],dataHbO[i],dataHbR[i],dataHbT[i]]);        
            }
            labelsData.push(millisToMinutesAndSeconds(timeData[i]));
            labelsData10mm.push(millisToMinutesAndSeconds(timeData[i]));
            labelsData27mm.push(millisToMinutesAndSeconds(timeData[i]));
          }

          // console.log(labelsData);
          // console.log(data850nm10mm);

          //csv data ordering
          arrayData.reverse();

          //chart data ordering
          labelsData.reverse();
          labelsData10mm.reverse();
          labelsData27mm.reverse();
          data740nm10mm.reverse();
          data850nm10mm.reverse();
          data940nm10mm.reverse();
          data740nm27mm.reverse();
          data850nm27mm.reverse();
          data940nm27mm.reverse();
          dataHbO.reverse();
          dataHbR.reverse();
          dataHbT.reverse();
          dataAmbient27mm.reverse();

          refreshChart10mm27mmHb();

      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });
    }

    //sequential load
    getTimeMetricQueryData();

  }

  const getTimeMetricQueryData = () => {

    var millisStart = startTime.getTime()/1000.0
    var millisEnd = endTime.getTime()/1000.0
    // console.log(millisStart)
    // console.log(millisEnd)

    //only allow less than 24 hour queries
    if ((millisEnd - millisStart) > 24*60*60){
      setShow(true);
      AlertDismissible();
      var millisStart = new Date().getTime()
      var millisEnd = new Date().getTime()
    }
    
    userID = window.userID;
    // console.log("query uid: " + userID);
    //console.log(rawDataComputedMetricDoc);

    db.collection("userComputedData/"+userID+"/computed_data")
    .where('timestamp', '>=', millisStart)
    .where('timestamp', '<=', millisEnd)
    .orderBy('timestamp', 'desc')
    .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var docValue = doc.data();
            timeMetricData.push(docValue["timestamp"]);
            mentalActivityData.push(docValue["mental_load_metric"]);
            stressData.push(docValue["flow_activity_ratio"]);
            energyData.push(docValue["energy"]);
            prvData.push(docValue["hrv"]);
            hrData.push(docValue["heartrate"]);
            if (docValue["peakToPeakMs"] != null){
              peakToPeakData.push(docValue["peakToPeakMs"]);
            }
            if (docValue["steps"] != null){
              stepData.push(docValue["steps"]);
            }
            if (docValue["accel_magnitude"] != null){
              accelMagData.push(docValue["accel_magnitude"]*100.0);
            }
            if (rawDataComputedMetricDoc){
              timeData.push(docValue["timestamp"]);
              if (docValue["avg740nm10mm"] != null){
                data740nm10mm.push(docValue["avg740nm10mm"]);
              }
              if (docValue["avg850nm10mm"] != null){
                data850nm10mm.push(docValue["avg850nm10mm"]);
              }
              if (docValue["avg940nm10mm"] != null){
                data940nm10mm.push(docValue["avg940nm10mm"]);
              }
              if (docValue["avg740nm27mm"] != null){
                data740nm27mm.push(docValue["avg740nm27mm"]);
              }
              if (docValue["avg850nm27mm"] != null){
                data850nm27mm.push(docValue["avg850nm27mm"]);
              }
              if (docValue["avg940nm27mm"] != null){
                data940nm27mm.push(docValue["avg940nm27mm"]);
              }
              if (docValue["avgAmbient27mm"] != null){
                dataAmbient27mm.push(docValue["avgAmbient27mm"]);
              }
            }
        });

        // console.log(timeMetricData);
        let tLen = timeMetricData.length;
        for (let i = 0; i < tLen; i++) {
          arrayMetricData.push([timeMetricData[i],mentalActivityData[i],stressData[i],energyData[i],prvData[i],hrData[i],peakToPeakData[i],stepData[i],accelMagData[i]]);
          labelsMetricData.push(secondsToMinutesAndSeconds(timeMetricData[i]));
          if (rawDataComputedMetricDoc){
            labelsData.push(millisToMinutesAndSeconds(timeData[i]*1000));
            arrayData.push([timeData[i],data740nm10mm[i],data940nm10mm[i],data850nm10mm[i],dataAmbient27mm[i],data740nm27mm[i],data940nm27mm[i],data850nm27mm[i],dataHbO[i],dataHbR[i],dataHbT[i]]);
          }
        }

        //csv data ordering
        arrayMetricData.reverse();

        //chart data ordering
        labelsMetricData.reverse();
        mentalActivityData.reverse();
        stressData.reverse();
        energyData.reverse();
        prvData.reverse();
        hrData.reverse();
        peakToPeakData.reverse();
        stepData.reverse();
        accelMagData.reverse();

        refreshMetricChart();

        if (rawDataComputedMetricDoc){
          timeData.reverse();
          labelsData.reverse();
          data740nm10mm.reverse();
          data850nm10mm.reverse();
          data940nm10mm.reverse();
          data740nm27mm.reverse();
          data850nm27mm.reverse();
          data940nm27mm.reverse();
          dataAmbient27mm.reverse()

          refreshChart10mm27mmHb();
        }

    })
    .catch((error) => {
        // console.log("Error getting documents: ", error);
    });
  }

  const clear_data = () => {
      arrayData = [];
      arrayMetricData = []
      arrayData10mm = [];
      arrayData27mm = [];
      timeData = [];
      labelsData = [];
      labelsData10mm = [];
      labelsData27mm = [];
      dataAmbient10mm = [];
      dataAmbient27mm = [];
      data740nm10mm = [];
      data940nm10mm = [];
      data850nm10mm = [];
      data740nm27mm = [];
      data940nm27mm = [];
      data850nm27mm = [];
      timeMetricData = [];
      labelsMetricData = [];
      mentalActivityData = [];
      stressData = [];
      energyData = [];
      prvData = [];
      hrData = [];
      peakToPeakData = [];
      stepData = [];
      accelMagData = [];
      // console.log('data cleared prior to query');
  }
  
  useEffect(() => { 
    //wait for login to persist
    setTimeout(function () {
        getTimeQueryData()
    }, 1000);
  }, []);

  const export_csv = () => {
    console.log(arrayData)
    let header = arrayHeader.join(",") + '\n';
    let csv = header;
    arrayData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_fnirs_data.csv';
    hiddenElement.click();
    console.log("fnirs - csv saved v2022");
  };

  const export_metric_csv = () => {
    console.log(arrayData)
    let header = arrayMetricHeader.join(",") + '\n';
    let csv = header;
    arrayMetricData.forEach( array => {
        csv += array.join(",")+"\n";
    });

    let csvData = new Blob([csv], { type: 'text/csv' });  
    let csvUrl = URL.createObjectURL(csvData);

    const now = Date.now();
    const nowDate = new Date(now);
    var csvTime = nowDate.toISOString(); // "2020-06-13T18:30:00.000Z"
    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = csvTime + '_metric_data.csv';
    hiddenElement.click();
    console.log("metric - csv saved v2022");
  };

  const millisToMinutesAndSeconds = (millis) => {
    //time is in GMT
    var gmtDate = new Date(millis);

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    
    return hours + ":" + minutes + ":" + seconds;
  }

  const secondsToMinutesAndSeconds = (totalSeconds) => {
    //time is in GMT
    var gmtDate = new Date(totalSeconds*1000);
    var localDate = gmtDate.toLocaleString();

    var seconds = gmtDate.getSeconds();
    var minutes = gmtDate.getMinutes();
    var hours = gmtDate.getHours();

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };

  const refreshChart10mm27mmHb = () => {

        setChartData10mm({
                      ...chartData10mm,
                      labels: labelsData,
                      datasets:[
                          {
                              ...chartData10mm.datasets[0],
                              data: data740nm10mm
                          },
                          {
                              ...chartData10mm.datasets[1],
                              data: data940nm10mm
                          },
                          {
                              ...chartData10mm.datasets[2],
                              data: data850nm10mm
                          }
                      ]})

       setChartData27mm({
                      ...chartData27mm,
                      labels: labelsData,
                      datasets:[
                          {
                              ...chartData27mm.datasets[0],
                              data: dataAmbient27mm
                          },
                          {
                              ...chartData27mm.datasets[1],
                              data: data740nm27mm
                          },
                          {
                              ...chartData27mm.datasets[2],
                              data: data940nm27mm
                          },
                          {
                              ...chartData27mm.datasets[3],
                              data: data850nm27mm
                          }
                      ]})

       setChartDataHB({
                      ...chartDataHB,
                      labels: labelsData,
                      datasets:[
                          {
                              ...chartDataHB.datasets[0],
                              data: dataHbO
                          },
                          {
                              ...chartDataHB.datasets[1],
                              data: dataHbR
                          },
                          {
                              ...chartDataHB.datasets[2],
                              data: dataHbT
                          }
                      ]})
  };

  const refreshMetricChart = () => {

        setChartMetricData({
                      ...chartMetricData,
                      labels: labelsMetricData,
                      datasets:[
                          {
                              ...chartMetricData.datasets[0],
                              data: mentalActivityData
                          },
                          {
                              ...chartMetricData.datasets[1],
                              data: stressData
                          },
                          {
                              ...chartMetricData.datasets[2],
                              data: energyData
                          },
                          {
                              ...chartMetricData.datasets[3],
                              data: prvData
                          },
                          {
                              ...chartMetricData.datasets[4],
                              data: hrData
                          },
                          {
                              ...chartMetricData.datasets[5],
                              data: peakToPeakData
                          },
                          {
                              ...chartMetricData.datasets[6],
                              data: stepData
                          },
                          {
                              ...chartMetricData.datasets[7],
                              data: accelMagData
                          }
                      ]})
  };


  const AlertDismissible = () => {
      return (
        <>
          <Alert show={show} variant="success">
            <Alert.Heading>Request alert limit!</Alert.Heading>
            <p>
              Presently we only allow a maximum period of 24 hours per request, please change your search to 24 hours or less
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <button onClick={() => setShow(false)} variant="outline-success">
                Close
              </button>
            </div>
          </Alert>
        </>
      );
  };
  
  return (
    <div className="app">
      <div className="App__DataDisplay">
        <AlertDismissible />
        <div className="header">
          <a href="https://www.blueberryx.com/" target="_blank"><img className="logo" src={logo} alt="logo"/></a>
          <div className="header-right">
            <DateTimePicker onChange={onChangeStart} value={startTime} />
            <DateTimePicker onChange={onChangeEnd} value={endTime} />
            <button className="blueButton" onClick={getTimeQueryData}>Get Data</button>
            <button className="blueButton" onClick={export_csv}>Download fNIRS CSV</button>
            <button className="blueButton" onClick={export_metric_csv}>Download Metric CSV</button>
            <button className="whiteButton" onClick={() => signOut(getAuth())}>Log out</button>
          </div>
        </div>
        <div className="row">
            
            
        </div>
        <div>
          <Line data={chartMetricData} width={window.innerWidth < 760 ? 270 : 400} style={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true,
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            }, 
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartData27mm} width={window.innerWidth < 760 ? 270 : 400} height={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true,
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            }, 
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartData10mm} width={window.innerWidth < 760 ? 270 : 400} height={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true, 
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
          <Line data={chartDataHB} width={window.innerWidth < 760 ? 270 : 400} height={200} options={{
            responsive: window.innerWidth < 760 ? false : true,
            spanGaps: true,
            animation: false,
            hover: true,
            legend: {
                labels: {
                  fontSize: window.innerWidth < 760 ? 5 : 12
                }
            },
            elements: {
                  point:{
                      radius: 0
                  }
            }, 
            scales: {
              yAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }} />
      </div>
      </div>
    </div>
  );
};
